import React from 'react';
import styled from 'styled-components';
import theme from '../theme';


const StyledList = styled.ul`
	list-style: none;
	margin: 25px;
	padding: 0;

	li {
		position: relative;
		padding-left: 20px;
		line-height: 1.6rem;

		&:before {
			content: "";
			display: inline-block;
			position: absolute;
			top: 8.5px;
			left: 0;
			width: 8px;
			height: 8px;
			border-radius: 50%;
			border: 2px solid ${props => theme.colors.blue};
		}
	}
`


const List = (props) => {
	return (
		<StyledList>
			{props.children}
		</StyledList>
	);
}

export default List;
